import { emptyWorkflow, Workflow } from "../../../shared/model/client";
import { emptyPatientPaymentMethod, PatientPaymentMethod } from "./patient-payment-method";
import { emptyPatientPaymentSchedule, PatientPaymentSchedule } from "./patient-payment.schedule";

export interface PatientPaymentProgram {
  patientId?: number;
  patientEncounterId?: number;
  patientPaymentProgramId: number;
  patientPaymentMethod: PatientPaymentMethod;
  isHighRisk: boolean;
  highRiskApprovalDt?: string;
  isPaidInFull: boolean; // TODO: DEPRECATED - Remove from the model when api changes are complete.
  downPmtAmt: number;
  isDownPmtAuthorized: boolean;
  isDownPmtCaptured: boolean;
  isACH: boolean;
  isACHVerified: boolean;
  areDocsSigned: boolean;
  workFlow: Workflow;
  workflowStatusDt: string;
  workflowDueDt: string;
  patientPaymentSchedule: PatientPaymentSchedule;
}

export const emptyPatientPaymentProgram: PatientPaymentProgram = {
  patientPaymentProgramId: 0,
  patientPaymentMethod: emptyPatientPaymentMethod,
  isHighRisk: false,
  highRiskApprovalDt: '',
  isPaidInFull: false, // TODO: DEPRECATED - Remove from the model when api changes are complete.
  downPmtAmt: 0,
  isDownPmtAuthorized: false,
  isDownPmtCaptured: false,
  isACH: false,
  isACHVerified: false,
  areDocsSigned: false,
  workFlow: emptyWorkflow,
  workflowStatusDt: '',
  workflowDueDt: '',
  patientPaymentSchedule: emptyPatientPaymentSchedule,
}
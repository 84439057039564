import {
    DialogActionButton,
    NewLOCModal,
    TextField,
} from '@finpay-development/shared-components';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
} from '@mui/material';
import {FormikProps, useFormik} from 'formik';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {clearStatus} from '../../../../admin-configuration/state/admin-configuration-slice';
import {ImplementationFacility} from '../../../../implementation-specialist/components/implementation-clients/details/models/implementation-facility';
import {
    showErrorStatus,
    showStatus,
} from '../../../../security/state/user-slice';
import {RootState} from '../../../../shared/state/root-reducer';
import {
    Estimate as NewEstimate,
    QuoteMethod,
    FacilityLevelOfCareWithCrossoverDays,
    ThirdPartyPayerStatus,
    PriorCareProvider,
    PriorCare,
    FacilityLevelOfCare,
    SummarySelections,
} from '@finpay/estimation-types';
import {Vob, VobPostBody} from 'src/admissions-advisor/models/vob';
import {callNewEstimatePut} from 'src/admissions-advisor/state/estimator-thunk';
import {Utils} from 'src/shared/utils';
import {saveVob} from 'src/admissions-advisor/state/vob-thunk';
import {AppDispatch} from 'src/shared/state/store';
import {admissionsAdvisorService} from 'src/admissions-advisor/services/admissions-advisor-services';
import {getFormikErrMsg} from '../estimator-loc-modal/estimator-loc-modal';

interface EstPriorCareModalProps {
    open: boolean;
    isEdit: boolean;
    facilities: ImplementationFacility[][];
    facilityId: number;
    handlePriorCareModalCancel: () => void;
    handlePriorCareModalSubmit: (isEdit: boolean) => void;
    vob: Vob;
    initialListOfLoc: FacilityLevelOfCareWithCrossoverDays[];
    priorCare?: PriorCare;
}

interface NameValue {
    name: string;
    value: number;
}

const NewPriorCareModal = (props: EstPriorCareModalProps) => {
    const {
        open,
        isEdit,
        facilities,
        facilityId,
        handlePriorCareModalCancel,
        handlePriorCareModalSubmit,
        vob,
        initialListOfLoc,
        priorCare,
    } = props;

    const newEstimate = useSelector((state: RootState) => {
        return state.admissionsAdvisorContext.estimatorContext.newEstimate;
    });
    const vobClassifications = useSelector(
        (state: RootState) =>
            state.admissionsAdvisorContext.vobContext?.vobClassifications
    );
    const vobPatientState = useSelector(
        (state: RootState) => state.admissionsAdvisorContext.vobPatientContext
    );

    const saveStatus = useSelector(
        (state: RootState) =>
            state.adminContext.adminConfigurationContext.modalSaveStatus
    );

    const errorMessage = useSelector(
        (state: RootState) =>
            state.adminContext.adminConfigurationContext.errorMessage
    );

    const priorCareProviders: NameValue[] = [
        {name: 'Client', value: PriorCareProvider.CLIENT},
        {name: 'Third Party', value: PriorCareProvider.THIRDPARTY},
    ];

    const thirdPartyProviderOptions: NameValue[] = [
        {name: 'Average In-Network', value: ThirdPartyPayerStatus.INNETW},
        {name: 'Average Out-of-Network', value: ThirdPartyPayerStatus.OONETW},
    ];

    const [listOfLoc, setListOfLoc] =
        useState<FacilityLevelOfCareWithCrossoverDays[]>(initialListOfLoc);

    useEffect(()=>{
        setListOfLoc(initialListOfLoc)
    }, [initialListOfLoc])

    const [modalFormik, setModalFormik] = useState<FormikProps<any> | null>(
        null
    );

    const dispatch = useDispatch<AppDispatch>();

    const priorCareModalFormik = useFormik({
        initialValues: {
            priorCareProvider:
                priorCare?.priorCareProvider || PriorCareProvider.CLIENT,
            thirdPartyPayerStatus:
                priorCare?.thirdPartyPayerStatus ||
                ThirdPartyPayerStatus.INNETW,
            facility: priorCare?.facilityId || facilityId,
        },
        onSubmit: () => {},
    });

    const handleNewSave = async () => {
        // get the formik values
        // make a put with the new values in the new type

        const formikErrorMsg = getFormikErrMsg(modalFormik?.errors)

        if(formikErrorMsg){
            dispatch(showErrorStatus(formikErrorMsg))
            return
        }

        if(!modalFormik?.dirty){
            dispatch(showStatus("No changes detected. Nothing to save."))
            return
        }

        const newEstimateCopy = Utils.deepClone(newEstimate);
        const {vobClassificationUpdates} = modalFormik?.values;

        const vobUpdateKeys = Object.keys(vobClassificationUpdates);
        const vobNeedsUpdate = vobUpdateKeys.length > 0;
        const inNetwork: boolean = vob?.payer?.inNetwork!;

        if (vobNeedsUpdate) {
            const vobCopy: Vob = Utils.deepClone(vob);

            const inNetwVobClassifications =
                vobCopy.inNetwVobClassifications || [];

            const ooNetwVobClassifications =
                vobCopy.ooNetwVobClassifications || [];

            vobUpdateKeys.forEach(key => {
                const {
                    toggleButtonSelection,
                    coPay,
                    coInsurance,
                    maxDays,
                    vobClassification,
                } = vobClassificationUpdates[key];
                let coPaySelection;

                switch (toggleButtonSelection) {
                    case 0:
                        coPaySelection = 'copayonly';
                        break;
                    case 1:
                        coPaySelection = 'n';
                        break;
                    case 2:
                        coPaySelection = 'y';
                        break;
                    default:
                        coPaySelection = 'copayonly';
                        break;
                }

                if (inNetwork) {
                    inNetwVobClassifications.push({
                        id: Math.random(),
                        selectedClassification: {
                            vobClassificationId:
                                vobClassification.vobClassificationId,
                            vobClassificationName: key,
                            vobClassificationDesc: key,
                            isInpatient: vobClassification.isInpatient,
                        },
                        coPaySelection,
                        coInsurance,
                        coPay,
                        maxDays,
                    });
                } else {
                    ooNetwVobClassifications.push({
                        id: Math.random(),
                        selectedClassification: {
                            vobClassificationId:
                                vobClassification.vobClassificationId,
                            vobClassificationName: key,
                            vobClassificationDesc: key,
                            isInpatient: vobClassification.isInpatient,
                        },
                        coPaySelection,
                        coInsurance,
                        coPay,
                        maxDays,
                    });
                }
            });

            const vobPostBody: VobPostBody = {
                advisorPatientId: vobPatientState.patient.advisorPatientId,
                fpClientId: vobCopy.client?.clientId,
                fpClientFacilityId: vobCopy.facility?.facilityId,
                vobBody: {
                    ...vobCopy,
                    inNetwVobClassifications,
                    ooNetwVobClassifications,
                },
            };
            try {
                await dispatch(saveVob(vobPostBody));
            } catch (e) {
                dispatch(showErrorStatus('Error updating VOB'));
            }
        }
        const {
            estimateId,
            crossOverSummary,
            summary,
            priorCareSummary,
            createUserId,
            createDt,
            lastUpdateUserId,
            lastUpdateDt,
            ...rest
        } = newEstimateCopy;

        const putEstimateBody: NewEstimate = {
            ...rest,
            summarySelection: SummarySelections.PRIORCARE,
            priorCare: {
                priorCareProvider:
                    priorCareModalFormik.values.priorCareProvider,
                thirdPartyPayerStatus:
                    priorCareModalFormik?.values?.thirdPartyPayerStatus,
                facilityId: priorCareModalFormik.values.facility,
                loc: modalFormik?.values?.selectedLocs,
            },
        };
        try {
            await dispatch(
                callNewEstimatePut({estimate: putEstimateBody, estimateId})
            );
            handleSaveCallback(true);
        } catch (e) {
            dispatch(showErrorStatus('Error updating Prior Care'));
        }
    };

    function handleSaveCallback(saveSuccessful: boolean) {
        if (saveSuccessful) {
            handlePriorCareModalSubmit(isEdit);
        } else {
            dispatch(showErrorStatus(errorMessage));
        }
        dispatch(clearStatus());
    }

    function handleCancelCallback() {
        handlePriorCareModalCancel();
    }

    const handleThirdPartyProviderChange = async (e: Event) => {
        const selection = Number((e.target as HTMLInputElement).value);
        const isClient = selection === PriorCareProvider.CLIENT;

        if (isClient) {
            const facilityId = priorCareModalFormik.values.facility;

            const response =
                await admissionsAdvisorService.getFacilityLevelsOfCare(
                    facilityId as number,
                    vob?.plan?.facilityPlanId!
                );

            setListOfLoc(response.hasErrors ? [] : response.entity);
        } else {
            const inn =
                priorCareModalFormik.values.thirdPartyPayerStatus ===
                ThirdPartyPayerStatus.INNETW
                    ? 'y'
                    : 'n';

            const response =
                await admissionsAdvisorService.getMasterFacilityLevelsOfCare(
                    facilityId,
                    inn
                );

            const newLoc = response.hasErrors
                ? []
                : response.entity?.filter((loc: FacilityLevelOfCare) => {
                      return loc.isCovered;
                  });

            setListOfLoc(newLoc);
        }

        modalFormik?.setValues({
            selectedLocs: [],
            vobClassificationUpdates: {}
        }, true)

        priorCareModalFormik.setFieldValue('priorCareProvider', selection);
    };

    const handleThirdPartyOptionsChange = async (e: Event) => {
        const selection = Number((e.target as HTMLInputElement).value);
        const inn = selection === ThirdPartyPayerStatus.INNETW ? 'y' : 'n';

        const response =
            await admissionsAdvisorService.getMasterFacilityLevelsOfCare(
                facilityId,
                inn
            );

        const newLoc = response.hasErrors
            ? []
            : response.entity?.filter((loc: FacilityLevelOfCare) => {
                  return loc.isCovered;
              });

        modalFormik?.setFieldValue('selectedLocs', [], true);
        setListOfLoc(newLoc);
    };

    const updateSelectedFacility = async (e: Event) => {
        const facilityId = +(e.target as HTMLInputElement).value;

        const response = await admissionsAdvisorService.getFacilityLevelsOfCare(
            facilityId,
            vob?.plan?.facilityPlanId!
        );

        // TODO: need to return empty array instead of nothing
        setListOfLoc(response.hasErrors ? [] : response.entity);
        modalFormik?.setFieldValue('selectedLocs', [], true);
    };

    const isValid = Boolean(modalFormik?.isValid && modalFormik?.dirty);

    return (
        <Dialog
            className="modal"
            open={open}
            fullWidth={true}
            maxWidth="lg"
            scroll="body"
        >
            <DialogTitle>
                {isEdit ? 'Edit' : 'Add'} Prior Levels of Care
            </DialogTitle>
            <DialogContent test-id="prior-care-modal-content">
                <>
                    <Grid
                        container
                        spacing={2}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                        }}
                    >
                        <Grid item direction="column" md={6} xs={6}>
                            <TextField
                                select
                                label="Prior Care Provider"
                                name="priorCareProvider"
                                value={
                                    priorCareModalFormik.values
                                        .priorCareProvider
                                }
                                onChange={(e: Event) => {
                                    priorCareModalFormik.handleChange(e);
                                    handleThirdPartyProviderChange(e);
                                }}
                                onBlur={priorCareModalFormik.handleBlur}
                                error={
                                    priorCareModalFormik.touched
                                        .priorCareProvider &&
                                    priorCareModalFormik.errors
                                        .priorCareProvider
                                }
                            >
                                {priorCareProviders.map(
                                    (provider: NameValue) => (
                                        <MenuItem
                                            key={provider.value}
                                            value={provider.value}
                                        >
                                            {provider.name}
                                        </MenuItem>
                                    )
                                )}
                            </TextField>
                        </Grid>
                        <Grid item direction="column" md={6} xs={6}>
                            {priorCareModalFormik.values.priorCareProvider ===
                            PriorCareProvider.THIRDPARTY ? (
                                <TextField
                                    select
                                    label="Third Party Payer Status"
                                    name="thirdPartyPayerStatus"
                                    value={
                                        priorCareModalFormik.values
                                            .thirdPartyPayerStatus
                                    }
                                    onChange={(e: Event) => {
                                        priorCareModalFormik.handleChange(e);
                                        handleThirdPartyOptionsChange(e);
                                    }}
                                    onBlur={priorCareModalFormik.handleBlur}
                                    error={
                                        priorCareModalFormik.touched
                                            .thirdPartyPayerStatus &&
                                        priorCareModalFormik.errors
                                            .thirdPartyPayerStatus
                                    }
                                >
                                    {thirdPartyProviderOptions.map(
                                        (option: NameValue) => (
                                            <MenuItem
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.name}
                                            </MenuItem>
                                        )
                                    )}
                                </TextField>
                            ) : (
                                <TextField
                                    select
                                    label="Facility"
                                    name="facility"
                                    value={priorCareModalFormik.values.facility}
                                    onChange={(e: Event) => {
                                        priorCareModalFormik.handleChange(e);
                                        updateSelectedFacility(e);
                                    }}
                                    onBlur={priorCareModalFormik.handleBlur}
                                    error={
                                        priorCareModalFormik.touched.facility &&
                                        priorCareModalFormik.errors.facility
                                    }
                                >
                                    {facilities
                                        .flat()
                                        .map(
                                            (
                                                facility: ImplementationFacility
                                            ) => (
                                                <MenuItem
                                                    key={facility.facilityId}
                                                    value={facility.facilityId}
                                                >
                                                    {facility.facilityName}
                                                </MenuItem>
                                            )
                                        )}
                                </TextField>
                            )}
                        </Grid>
                    </Grid>
                    <NewLOCModal
                        // always manual for the allow user to choose days/sessions
                        quoteMethod={QuoteMethod.MANUAL}
                        initialListOfSelectedLoc={
                            newEstimate?.priorCare
                                ?.loc as FacilityLevelOfCareWithCrossoverDays[]
                        }
                        listOfLoc={listOfLoc}
                        // set the date to the epoch, 1970-01-01
                        admissionDate={new Date(0)}
                        policyEndDate={new Date(vob.policyEndDate)}
                        vob={vob}
                        vobClassifications={vobClassifications}
                        getFormikValues={formik => {
                            setModalFormik(formik as FormikProps<any>);
                        }}
                    />
                </>
            </DialogContent>
            <DialogActions test-id="prior-care-modal-actions">
                <DialogActionButton
                    test-id="prior-care-modal-save-button"
                    isEnabled={true}
                    savebuttonText={isEdit ? 'Update' : 'Save'}
                    saveStatus={saveStatus}
                    spinnerLeftPosition={5}
                    executeSave={handleNewSave}
                    handleCallbackSave={handleSaveCallback}
                    handleCallbackCancel={handleCancelCallback}
                />
            </DialogActions>
        </Dialog>
    );
};

export default NewPriorCareModal;
